// TODO: REFACTORING
import React from 'react';
import ImageCardOverlay from '../../../ImageCardOverlay/ImageCardOverlay';
import { translate as t, useCurrentLang } from '@utils/translate';
import Style from './style.module.css';
import aircraftModelNameHelper from '@utils/aircraftModelNameHelper';

const FleetItemTrendingFleet = ({ item, handleClick, index }) => {
  const model = item.model;
  const imageUrl = model.highlightImageUrl;

  const aircraftModelName = useMemo(() => {
    return aircraftModelNameHelper(aircraft);
  }, [model.id]);

  const TEXTS = {
    PLACES: t('places'),
  };

  return (
    <div className="fleet-section__item" onClick={handleClick} index={index}>
      <ImageCardOverlay imageSrc={imageUrl}>
        <p className={Style.aircraftSize}>
          {model.aircraftSize && model.aircraftSize.name}
        </p>
        <p className={Style.pax}>
          <span className={[Style.paxIcon, 'material-icons'].join(' ')}>
            airline_seat_recline_extra
          </span>
          {`${model.quantityOfPassengers} ${TEXTS.PLACES}`}
        </p>
      </ImageCardOverlay>
      <p className="fleet-section__item__title">{aircraftModelName}</p>
    </div>
  );
};

FleetItemTrendingFleet.defaultProps = {
  index: null,
};

export default FleetItemTrendingFleet;
