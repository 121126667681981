/**
 * Source Gist:
 *
 * @see https://gist.github.com/joshuacerbito/ea318a6a7ca4336e9fadb9ae5bbb87f4
 *
 */

import { useState, useEffect } from 'react';

export const useScroll = () => {
  // Set a single object `{ scrollX: ..., scrollY: ..., direction: ... }` once on init
  const [scroll, setScroll] = useState({
    scrollX: 0,
    scrollY: 0,
    direction: '',
  });

  const listener = e => {
    // `prev` provides us the previous state: https://reactjs.org/docs/hooks-reference.html#functional-updates
    setScroll(prev => ({
      scrollX: document.body.getBoundingClientRect().left,
      scrollY: -document.body.getBoundingClientRect().top,
      // Here we’re comparing the previous state to the current state to get the scroll direction
      direction:
        prev.scrollY > -document.body.getBoundingClientRect().top
          ? 'up'
          : 'down',
    }));
  };

  useEffect(() => {
    listener();
    window.addEventListener('scroll', listener);
    // cleanup function occurs on unmount
    return () => window.removeEventListener('scroll', listener);
    // Run `useEffect` only once on mount, so add `, []` after the closing curly brace }
  }, []);

  return scroll;
};

export default useScroll;
