const FleetItemCategorySlickConfig = {
  slidesToShow: 7,
  infinite: false,
  swipe: true,
  dots: false,
  infinite: false,
  responsive: [
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        arrows: false,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 3,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        arrows: false,
      },
    },
  ],
};

export default FleetItemCategorySlickConfig;
