// TODO: TRANSLATION PATTERN
// TODO: REFACTORING
import React, { useState } from 'react';
import Style from './style.module.css';
import FleetAircraftItem from '../FleetAircraftItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  setSelectedSearchTerm,
  setSearchTextValue,
  searchSuggestions,
  fetchAircraft,
} from '@store/reducers/fleet/operations';
import { useStore, connect } from 'react-redux';

const FleetAircraftList = ({ items, pagination }) => {
  let store = useStore();
  let components = items.map((aircraft, key) => {
    return <FleetAircraftItem aircraft={aircraft} key={aircraft.id} />;
  });
  return (
    <div className={Style.fleetAircraftListContainer}>
      <div className={Style.fleetAircraftList}>
        <InfiniteScroll
          dataLength={items.length} //This is important field to render the next data
          next={() => {
            store.dispatch(fetchAircraft(pagination.page + 1));
          }}
          className={Style.infinitescrollFix}
          hasMore={pagination.totalLoadedItems < pagination.totalItems}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          {components}
        </InfiniteScroll>
      </div>
    </div>
  );
};

FleetAircraftList.defaultProps = {
  items: [],
};

export default FleetAircraftList;
