// TODO: REFACTORING
import React from 'react';
import './style.css';
import '../slick.css';
import Img from '@components/ImageWrapper';

const CoverImageHero = ({ imageSrc, children, className, innerRef }) => {
  return (
    <section
      className={['cover-image-hero', className].join(' ')}
      ref={innerRef}
    >
      <Img
        src={imageSrc}
        style={{ position: 'absolute', overflow: 'inherit' }}
        className="cover-image-hero__image"
        imgStyle={{ objectPosition: 'center top' }}
        // alt={alt}
      />
      <div className="cover-image-hero__content-wrapper container">
        {children}
      </div>
    </section>
  );
};

export default CoverImageHero;
