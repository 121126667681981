import React, { useRef, useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import useScroll from '../utils/useScroll';
import FleetHero from '../components/FleetHero';
import FleetSection from '../components/FleetSection';
import FleetCollapsedSearch from '../components/FleetCollapsedSearch';
import { Creators } from '@store/reducers/fleet/actions';
import { useStore, connect } from 'react-redux';
import FleetAircraftList from '@components/FleetAircraftList';

const mapStateToProps = state => {
  const { fleet } = state;
  return {
    aircraftsResult: fleet.aircraftsResult,
    selectedSearchTerm: fleet.selectedSearchTerm,
    fetchingAircraft: fleet.fetchingAircraft,
  };
};

const makeComponents = components => {
  let mainCategorySection = null;
  let feedComponents = components.reduce((accumulator, section, index) => {
    let component = (
      <FleetSection
        title={section.title}
        items={section.items}
        type={section.type}
        key={index}
      />
    );
    if (section.type == 'FleetCategory') {
      mainCategorySection = component;
      return accumulator;
    }
    accumulator.push(component);
    return accumulator;
  }, []);

  return [mainCategorySection, feedComponents];
};

// const FleetWrapper = (data) => {
//   return (
//     <FleetSearchContextProvider>
//       <Fleet {...data} />
//     </FleetSearchContextProvider>
//   );
// };

const Fleet = ({ pageContext }) => {
  const {
    title,
    yoast_head,
    polylang_current_lang,
    polylang_translations,
    acf: {
      fleet_subtitle: subtitle,
      fleet_pretitle: pretitle,
      fleet_image: image,
      fleet_searchterm_endpoint: searchTermEndPoint,
      fleet_searchterm_parameters: defaultSearchTermParameters,
      fleet_aircraftget_endpoint: aircraftGetEndpoint,
      fleet_parameters_aircraft_get: defaultAircraftGetParameters,
    },
    content: contentSections,
    filters: defaultSuggestions,
  } = pageContext.data;

  const store = useStore();
  const state = store.getState().fleet;
  const [collapsedSearch, setCollapsedSearch] = useState(false);
  const fleetHeroRef = useRef(null);
  const aircraftListRef = useRef(null);
  const { scrollY } = useScroll();
  const [mainCategorySection, feedComponents] = makeComponents(contentSections);

  useEffect(() => {
    store.dispatch(Creators.setSuggestions({ defaultSuggestions }));
    store.dispatch(
      Creators.setSearchTermEndpoint({
        url: searchTermEndPoint,
        params: defaultSearchTermParameters.reduce((acc, param) => {
          acc[param.name] = param.value;
          return acc;
        }, {}),
      })
    );
    store.dispatch(
      Creators.setAircraftGetEndpoint({
        url: aircraftGetEndpoint,
        params: defaultAircraftGetParameters.reduce((acc, param) => {
          acc[param.name] = param.value;
          return acc;
        }, {}),
      })
    );

    // store.dispatch(Creators.setSearchAircraftResult(AIRCRAFTS_MOCKUP));
  }, []);

  useEffect(() => {
    if (fleetHeroRef.current != null) {
      setCollapsedSearch(scrollY + 120 > fleetHeroRef.current.offsetHeight);
    }
  }, [scrollY]);

  const hideFeed =
    (state.aircraftsResult != null || state.fetchingAircraft) &&
    state.selectedSearchTerm != null;

  // const hideFeed = true;

  return (
    <Layout
      footerStyles={{ marginBottom: '104px' }}
      pageLang={polylang_current_lang}
      solidHeader={collapsedSearch || hideFeed ? 'white' : null}
      translations={polylang_translations}
      useTranslationsPath={true}
    >
      <SEO
        yoast_head={yoast_head}
        pageLang={polylang_current_lang}
        translations={polylang_translations}
      />
      {!hideFeed && (
        <FleetHero
          pretitle={pretitle}
          subtitle={subtitle}
          image={image}
          mainCategorySection={mainCategorySection}
          innerRef={fleetHeroRef}
        />
      )}
      <FleetCollapsedSearch isVisible={collapsedSearch || hideFeed} />
      {!hideFeed && feedComponents}
      {hideFeed && state.aircraftsResult && (
        <FleetAircraftList
          ref={aircraftListRef}
          items={state.aircraftsResult}
          pagination={state.aircraftsResultPagination}
        />
      )}
    </Layout>
  );
};

export default connect(mapStateToProps, null)(Fleet);
