// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';
import { translate as t } from '@utils/translate';
import ImageCardOverlay from '../../../ImageCardOverlay/ImageCardOverlay';
import aircraftNameHelper from '@utils/aircraftNameHelper';

const FleetItemNewestHomologated = ({ item, handleClick }) => {
  const aircraft = item.aircraft,
    model = aircraft.model,
    imageUrl = aircraft.highlightImageUrl || model.highlightImageUrl;

  const aircraftName = useMemo(() => {
    return aircraftNameHelper(aircraft);
  }, [aircraft.id]);

  const amenities =
    (aircraft.amenities && aircraft.amenities.slice(0, 6)) || [];

  const TEXTS = {
    YEARS: t('years'),
  };

  return (
    <div onClick={handleClick}>
      <ImageCardOverlay imageSrc={imageUrl}>
        <p className={Style.aircraftSize}>
          {model.aircraftSize && model.aircraftSize.name}
        </p>
        <p className={Style.baseCity}>
          {aircraft.baseSpot && aircraft.baseSpot.address.city.name}
        </p>
        <div className={Style.amenityList}>
          {amenities.map((data, index) => (
            <div className={Style.amenity} key={index}>
              <img src={data.iconImageUrl} />
            </div>
          ))}
        </div>
        {/* <p className={Style.pax}>
          <span className={[Style.paxIcon, "material-icons"].join(" ")}>
            airline_seat_recline_extra
          </span>
          {`${model.quantityOfPassengers} ${translate("Lugares")}`}
        </p> */}
      </ImageCardOverlay>
      <p className={Style.itemTitle}>{aircraftName}</p>
      {aircraft.paintingYear && (
        <p className={Style.itemSubtitle}>
          {`${TEXTS.YEARS} ${aircraft.paintingYear}`}
        </p>
      )}
    </div>
  );
};

export default FleetItemNewestHomologated;
