// TODO: REFACTORING
import React from 'react';
import ImageCardOverlay from '../../../ImageCardOverlay/ImageCardOverlay';

const FleetItemCategory = ({ item, handleClick }) => {
  return (
    <div onClick={handleClick} className="fleet-section__item">
      <ImageCardOverlay imageSrc={item.imageUrl} forceHoverState={true}>
        <p className="fleet-section__item__title">{item.title}</p>
        <p className="fleet-section__item__subtitle">{item.subtitle}</p>
      </ImageCardOverlay>
    </div>
  );
};

export default FleetItemCategory;
