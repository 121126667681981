// TODO: REFACTORING
import React, { useState } from 'react';
import './style.css';
import '../slick.css';
import FeedSlider from '../FeedSlider';
import FleetItemLocation from './components/FleetItemLocation';
import FleetItemCategory from './components/FleetItemCategory';
import FleetItemTrendingFleet from './components/FleetItemTrendingFleet';
import FleetItemNewestHomologated from './components/FleetItemNewestHomologated/FleetItemNewestHomologated';

import FleetItemCategorySlickConfig from './components/FleetItemCategory/slickConfig';

import { translate as t, useCurrentLang } from '@utils/translate';
import makeAircraftLink from '@utils/makeAircraftLink';
import { useStore } from 'react-redux';
import { Link } from 'gatsby';

import { setSelectedSearchTerm } from '@store/reducers/fleet/operations';
import {
  TERM_TYPE_AIRCRAFT_MODEL,
  TERM_TYPE_BASE_CITY,
} from '@store/reducers/fleet/term-types';

const TypeStyleMapping = {
  FleetCategory: {
    className: 'fleet-section--theme-main-category',
    includeMoreButton: false,
    includeBottomLine: false,
    handleClick: (item, store) => {
      store.dispatch(
        setSelectedSearchTerm({
          text: item.title,
          argumentType: TERM_TYPE_AIRCRAFT_MODEL,
          argumentValue: item.model.id,
        })
      );
    },
    builder: ({ item, handleClick, key }) => {
      return (
        <FleetItemCategory item={item} handleClick={handleClick} key={key} />
      );
    },
    slickConfig: FleetItemCategorySlickConfig,
  },
  TrendingFleet: {
    className: 'fleet-section--type-feed fleet-section--theme-trending',
    includeMoreButton: true,
    includeBottomLine: true,
    handleClick: (item, store) => {
      store.dispatch(
        setSelectedSearchTerm({
          text: item.title,
          argumentType: TERM_TYPE_AIRCRAFT_MODEL,
          argumentValue: item.model.id,
        })
      );
    },
    builder: ({ item, handleClick, key }) => {
      return (
        <FleetItemTrendingFleet
          item={item}
          handleClick={handleClick}
          key={key}
        />
      );
    },
  },

  Location: {
    className: 'fleet-section--type-feed fleet-section--theme-location',
    includeMoreButton: true,
    includeBottomLine: true,
    handleClick: (item, store) => {
      store.dispatch(
        setSelectedSearchTerm({
          text: item.title,
          argumentType: TERM_TYPE_BASE_CITY,
          argumentValue: item.city.id,
        })
      );
    },
    builder: ({ item, handleClick, key }) => {
      return (
        <FleetItemLocation item={item} handleClick={handleClick} key={key} />
      );
    },
  },

  NewestHomologated: {
    className: 'fleet-section--type-feed  fleet-section--theme-homologated',
    includeMoreButton: true,
    includeBottomLine: true,
    handleClick: null,
    builder: ({ item, key, language }) => {
      return (
        <Link to={makeAircraftLink(item.aircraft, language)} key={key}>
          <FleetItemNewestHomologated item={item} />
        </Link>
      );
    },
  },
};

function _generateComponent(typeView, components, slickConfig) {
  if (typeView == 'grid') {
    return {
      typeView: typeView,
      component: <div className="fleet-section__grid">{components}</div>,
    };
  } else {
    return {
      typeView: typeView,
      component: (
        <FeedSlider
          items={components}
          className="fleet-section__slider"
          slickConfig={slickConfig}
        />
      ),
    };
  }
}

const FleetSection = ({ title, subtitle, type = '*', children, items }) => {
  if (type in TypeStyleMapping == false || items.length == 0) return <></>;
  let typeStyle = TypeStyleMapping[type];
  const slickConfig = typeStyle.slickConfig;
  const store = useStore();
  const language = useCurrentLang();

  const componentsItems = items.map((item, key) =>
    typeStyle.builder({
      item,
      handleClick: typeStyle.handleClick
        ? typeStyle.handleClick.bind(null, item, store)
        : null,
      key,
      language,
    })
  );

  const [viewData, setViewType] = useState(
    _generateComponent('slide', componentsItems, slickConfig)
  );

  const toogleView = () => {
    setViewType(
      _generateComponent(
        viewData.typeView == 'slide' ? 'grid' : 'slide',
        componentsItems,
        slickConfig
      )
    );
  };

  const TEXTS = {
    SEE_ALL: t('seeAll'),
    HIDE: t('hide'),
  };

  return (
    <section
      className={[
        'fleet-section',
        typeStyle.className,
        `fleet-section--type-${viewData.typeView}`,
      ].join(' ')}
    >
      <div className="fleet-section__wrapper">
        <div className="fleet-section__header">
          <h2 className="fleet-section__title">{title}</h2>
          <p className="fleet-section__subtitle">{subtitle}</p>
        </div>
        {viewData.component}
        {typeStyle.includeMoreButton && (
          <div className="fleet__show-all-btn">
            <p onClick={toogleView}>
              {viewData.typeView == 'slide' ? TEXTS.SEE_ALL : TEXTS.HIDE}
            </p>
            <span className="material-icons fleet__show-all-btn__arrow">
              keyboard_arrow_right
            </span>
          </div>
        )}
        {typeStyle.includeBottomLine && (
          <div className="fleet__bottom-line"></div>
        )}
      </div>
    </section>
  );
};

FleetSection.defaultProps = {
  items: [],
};

export default FleetSection;
