import { dateToYear } from '../utils/dates';

const getExpirationDate = expDate =>
  new Date(expDate).getFullYear() - new Date().getFullYear();

export const mapPropertiesSafetyCertification = certificationData => {
  return {
    firstRender: certificationData.certification.name,
    secRender: [
      getExpirationDate(certificationData.expirationDate),
      dateToYear(certificationData.certificationDate),
    ],
    thirdRender: {
      name: 'Brasil',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="14.599" height="11.517" viewBox="0 0 14.599 11.517"><g transform="translate(0 0)"><path fill="#ffda44" d="M51.822,100.174l7.3,5.759-7.3,5.759-7.3-5.759Z" transform="translate(-44.522 -100.174)"/><ellipse fill="#f0f0f0" cx="3.074" cy="3.074" rx="3.074" ry="3.074" transform="translate(4.226 2.706)"/><g transform="translate(4.227 2.706)"><path fill="#0052b4" d="M168.521,250.435a5.169,5.169,0,0,0-1.536.232,3.073,3.073,0,0,0,5.59,1.723A5.18,5.18,0,0,0,168.521,250.435Z" transform="translate(-166.985 -247.554)"/><path fill="#0052b4" d="M180.016,170.619a3.074,3.074,0,0,0-5.841-1.8,6.336,6.336,0,0,1,5.841,1.8Z" transform="translate(-173.927 -166.957)"/></g></g></svg>`,
    },
    memberSince: dateToYear(
      certificationData.certification.firstCertificatedAt ||
        certificationData.firstCertificatedAt
    ),
    image: certificationData.certification.iconImageUrl,
    category: certificationData.certification.category,
  };
};
