// TODO: CHECK USE
// TODO: REFACTORING
import React from 'react';
import Style from './style.css';

const ImageCardOverlay = ({
  children,
  imageSrc,
  handleClick,
  forceHoverState,
}) => {
  return (
    <div
      className={[
        'image-card-overlay',
        forceHoverState ? 'activeHover' : '',
      ].join(' ')}
      onClick={handleClick}
      // style={{ width: size[0], height: size[1] }}
    >
      <img className="image-card-overlay__image" src={imageSrc} />

      <div className="image-card-overlay__overlay">
        <div className="image-card-overlay__overlay-inner">{children}</div>
      </div>
    </div>
  );
};

ImageCardOverlay.defaultProps = {
  size: [100, 100],
  forceHoverState: false,
};

export default ImageCardOverlay;
