// TODO: REFACTORING
import React, { useState } from 'react';
import Style from './style.module.css';
import FleetSearchField from '../FleetSearchField/FleetSearchField';

const FleetCollapsedSearch = ({ isVisible }) => {
  return (
    <div
      className={[Style.container, isVisible ? Style.visible : null].join(' ')}
    >
      <FleetSearchField className="fleet-search-field--dark" />
    </div>
  );
};

export default FleetCollapsedSearch;
