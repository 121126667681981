// TODO: REFACTORING
import React from 'react';
import './style.css';
import CoverImageHero from '../CoverImageHero';
import FleetSearchField from '../FleetSearchField/FleetSearchField';

const FleetHero = ({
  pretitle,
  subtitle,
  image,
  mainCategorySection,
  innerRef,
}) => {
  return (
    <CoverImageHero
      imageSrc={image}
      className="fleet-hero__cover"
      innerRef={innerRef}
    >
      <div className="fleet-hero__header">
        <div className="fleet-hero__info">
          <h1>{pretitle}</h1>
          <p className="fleet-hero__info-subtitle">{subtitle}</p>
        </div>
        <div className="fleet-hero__search-field">
          <FleetSearchField></FleetSearchField>
        </div>
      </div>

      <div className="fleet-hero__categories">{mainCategorySection}</div>
    </CoverImageHero>
  );
};

export default FleetHero;
