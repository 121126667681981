// TODO: REFACTORING
import React from 'react';
import ImageCardOverlay from '../../../ImageCardOverlay/ImageCardOverlay';

const FleetItemLocation = ({ item, handleClick }) => {
  // const imageUrl =
  // item.aircraft.highlightImageUrl || item.aircraft.model.highlightImageUrl;
  return (
    <div onClick={handleClick} className="fleet-section__item">
      <ImageCardOverlay imageSrc={item.imageUrl}>
        <p className="text-center">{item.subtitle}</p>
      </ImageCardOverlay>
      <p className="fleet-section__item__title">{item.title}</p>
    </div>
  );
};

export default FleetItemLocation;
