// TODO: REFACTORING
import React, { useState, useEffect, useRef } from 'react';
// import { useMediaQuery } from "react-responsive";
import './style.css';
import { useStore, connect } from 'react-redux';
import { translate as t } from '@utils/translate';
import {
  setSelectedSearchTerm,
  setSearchTextValue,
  searchSuggestions,
  fetchAircraft,
} from '@store/reducers/fleet/operations';
import { TERM_TYPE_AIRCRAFT_MODEL } from '@store/reducers/fleet/term-types';
import useOutsideClick from '@utils/useOutsideClick';
import { withBreakpoints } from 'gatsby-plugin-breakpoints';

const mapStateToProps = state => {
  const { fleet } = state;
  return {
    suggestions: fleet.suggestions,
    searchTextValue: fleet.searchTextValue,
    selectedSearchTerm: fleet.selectedSearchTerm,
  };
};

const HintSection = ({ onSelectHint }) => {
  const store = useStore();
  const state = store.getState().fleet;
  const hints =
    state.suggestions == null ? state.defaultSuggestions : state.suggestions;

  const handlerClick = hintData => {
    onSelectHint();
    store.dispatch(
      setSelectedSearchTerm({
        text: hintData.term || hintData.title,
        argumentType: TERM_TYPE_AIRCRAFT_MODEL,
        argumentValue: hintData.id,
      })
    );
  };
  const TEXTS = {
    SUGESTIONS: t('Sugestões'),
  };
  return (
    <div className="fleet-search-field__hint-options">
      <div className="fleet-search-field__hint-options-inner">
        <h3 className="fleet-search-field__hint-title">{TEXTS.SUGESTIONS}</h3>
        <div className="fleet-search-field__hint-items-wrapper">
          {hints.map((hint, index) => {
            return (
              <p
                onClick={handlerClick.bind(null, hint)}
                key={index}
                className="fleet-search-field__hint-item"
              >
                {hint.title || hint.term}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const SearchButton = ({ handlerClick }) => {
  return (
    <div className="fleet-search-field__wrapper-btn" onClick={handlerClick}>
      <div className="fleet-search-field__btn">
        <span className="material-icons">search</span>
      </div>
    </div>
  );
};

const FleetSearchField = ({ className, breakpoints }) => {
  const store = useStore();
  const ref = useRef(null);
  const state = store.getState().fleet;
  // const isMobile = useMediaQuery({ query: "(max-device-width: 600px)" });
  const [hasFocus, setStateFocused] = useState(false);
  const inputTextRef = useRef(null);
  const searchTermText = state.searchTextValue;

  const searchAction = () => {
    store.dispatch(fetchAircraft());
  };

  const handleChangeSearchTerm = () => {
    store.dispatch(setSearchTextValue(inputTextRef.current.value));
    store.dispatch(searchSuggestions(inputTextRef.current.value));
    // store.dispatch(searchSuggestions(inputTextRef.current.value));
  };

  const handleRemoveSelectedTerm = () => {
    store.dispatch(setSelectedSearchTerm(null));
  };

  const handleSelectHint = () => {
    setStateFocused(0);
  };

  useOutsideClick(ref, () => {
    if (hasFocus) {
      setStateFocused(0);
    }
  });

  return (
    <div
      ref={ref}
      className={[
        'fleet-search-field',
        className,
        state.selectedSearchTerm ? 'fleet-search-field--option-selected' : '',
        hasFocus ? 'fleet-search-field--active' : '',
      ].join(' ')}
    >
      <div className="fleet-search-field__wrapper">
        <div className="fleet-search-field__input-wrapper">
          <div className="fleet-search-field__opt-selected">
            {state.selectedSearchTerm && (
              <div className="fleet-search-field__opt-item">
                {state.selectedSearchTerm.text}
                <span
                  className="material-icons"
                  onClick={handleRemoveSelectedTerm}
                >
                  close
                </span>
              </div>
            )}
          </div>
          <input
            ref={inputTextRef}
            value={searchTermText}
            className="fleet-search-field__input"
            onFocus={() => setStateFocused(1)}
            // onBlur={() => setStateFocused(0)}
            onChange={handleChangeSearchTerm}
            placeholder="Pesquise um modelo ou aeronave"
          />
          {!breakpoints.sm ? (
            <HintSection onSelectHint={handleSelectHint} />
          ) : (
            <SearchButton handlerClick={searchAction} />
          )}
        </div>
        {breakpoints.sm ? (
          <HintSection onSelectHint={handleSelectHint} />
        ) : (
          <SearchButton handlerClick={searchAction} />
        )}
      </div>
    </div>
  );
};

FleetSearchField.defaultProps = {
  className: 'fleet-search-field--light',
};

export default connect(
  mapStateToProps,
  null
)(withBreakpoints(FleetSearchField));
