import { Creators } from './actions';
import useRequest from '@utils/useRequest';
import { apiHost } from '@utils/constants';
import { batch } from 'react-redux';

export const setSearchTextValue = Creators.setSearchTextValue;
export const setSuggestions = Creators.setSuggestions;
export const setSearchResultAircrafts = Creators.setSearchResultAircrafts;
export const setSearchResultPagination = Creators.setSearchResultPagination;
export const setAircraftGetEndpoint = Creators.setAircraftGetEndpoint;
export const setSearchTermEndpoint = Creators.setSearchTermEndpoint;
export const startFetchingAircraft = Creators.startFetchingAircraft;
export const endFetchingAircraft = Creators.endFetchingAircraft;
export const setAircraftModelId = Creators.setAircraftModelId;
export const reset = Creators.reset;

export const fetchAircraft = (page = 1, limit = 20) => {
  return (dispatch, getState) => {
    const state = getState().fleet;
    if (!state.selectedSearchTerm) return null;
    const aircraftGetEndpoint = state.aircraftGetEndpoint;
    const term = state.selectedSearchTerm;
    dispatch(startFetchingAircraft());
    useRequest({
      url: `${apiHost}${aircraftGetEndpoint.url}`,
      params: {
        ...aircraftGetEndpoint.params,
        details: 1,
        pagination: 1,
        page: page,
        limit: limit,
        [term.argumentType]: term.argumentValue,
      },
    })
      .then(({ data }) => {
        let aircrafts =
          page == 1
            ? data.aircrafts
            : [...state.aircraftsResult, ...data.aircrafts];

        batch(() => {
          dispatch(
            setSearchResultPagination({
              limit: data.limit,
              page: page,
              totalPages: data.totalPages,
              totalItems: data.totalItems,
              totalLoadedItems: aircrafts.length,
            })
          );
          dispatch(setSearchResultAircrafts(aircrafts));
          dispatch(endFetchingAircraft());
        });
      })
      .catch(e => {
        console.log(e);
        dispatch(endFetchingAircraft());
      });
  };
};

export const setSelectedSearchTerm = term => {
  return (dispatch, getState) => {
    const state = getState().fleet;
    if (term != state.selectedSearchTerm) {
      dispatch(Creators.setSelectedSearchTerm(term));
      dispatch(fetchAircraft());
    }
  };
};

export const searchSuggestions = term => {
  return (dispatch, getState) => {
    const { searchTermEndPoint } = getState().fleet;

    useRequest({
      url: `${apiHost}${searchTermEndPoint.url}`,
      params: {
        ...searchTermEndPoint.params,
        term: term,
      },
    }).then(({ data }) => {
      dispatch(
        setSuggestions({ suggestions: data.searchables.slice(0, 10) || [] })
      );
    });
  };
};

export default {
  setSearchTextValue,
  setSelectedSearchTerm,
  fetchAircraft,
  setSuggestions,
  setAircraftGetEndpoint,
  setSearchTermEndpoint,
  searchSuggestions,
  setAircraftModelId,
  reset,
};
