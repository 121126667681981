// TODO: REFACTORING
import React from 'react';
import Slider from 'react-slick';
import ArrowButton from './components/ArrowButton';
import './style.css';

const FeedSlider = ({ items, className, slickConfig }) => {
  let sliderSettings = {
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: true,
    swipe: true,
    dots: false,
    infinite: false,
    prevArrow: (
      <ArrowButton
        type="prev"
        _className={[
          'feed-slider__arrow-button',
          'feed-slider__arrow-button--prev',
          className ? `${className}__prev-button` : '',
        ].join(' ')}
      />
    ),
    nextArrow: (
      <ArrowButton
        type="next"
        _className={[
          'feed-slider__arrow-button',
          'feed-slider__arrow-button--next',
          className ? `${className}__next-button` : '',
        ].join(' ')}
      />
    ),
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  if (slickConfig) sliderSettings = { ...sliderSettings, ...slickConfig };

  return (
    <Slider
      {...sliderSettings}
      className={['feed-slider', className].join(' ')}
    >
      {items}
    </Slider>
  );
};

export default FeedSlider;
