// TODO: REFACTORING
import React, { useState, useCallback, useMemo } from 'react';
import Style from './style.module.css';
import './style.css';
import { translate as t, useCurrentLang } from '../../utils/translate';
import AircraftGallery from '../AircraftGallery';
import { Components as AircraftSecurityComponents } from '../AircraftSecurity';
import { mapPropertiesSafetyCertification } from '@utils/mapPropertiesSafetyCertification';
import makeAircraftLink from '@utils/makeAircraftLink';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'gatsby';
import aircraftNameHelper from '@utils/aircraftNameHelper';

const FleetAircraftItem = ({ aircraft, className }) => {
  const governmentCertification = aircraft.certifications.find(
    data => data.certification.category == 'government'
  );

  const language = useCurrentLang();
  const model = aircraft.model;
  const aircraftName = useMemo(() => {
    return aircraftNameHelper(aircraft);
  }, [aircraft.id]);

  const amenities = aircraft.amenities.slice(0, 4);
  const isMobile = useMediaQuery({ query: '(max-device-width: 600px)' });

  const [modalOpened, setModalOpened] = useState(false);

  const handleToggleModal = useCallback(modalState => {
    setModalOpened(modalState);
  }, []);
  const TEXTS = {
    AVAILABLE_PLACES: t('Lugares disponíveis'),
    QUOTATION: t('Realizar cotação'),
  };
  return (
    <section
      className={[
        Style.fleetAircraftItem,
        'fleet-aircraft-item',
        className,
      ].join(' ')}
    >
      <div
        className={[
          'fleet-aircraft-item__wrapper',
          modalOpened ? 'modal-opened' : '',
        ].join(' ')}
      >
        <AircraftGallery
          className="fleet-aircraft-item__gallery"
          images={aircraft.pictures}
          onToggleModal={handleToggleModal}
        />
        <div className="fleet-aircraft-item__info">
          <div className="fleet-aircraft-item__model">
            <h3 className="fleet-aircraft-item__title">{aircraftName}</h3>
            <p className="fleet-aircraft-item__subtitle">
              {model.aircraftSize && model.aircraftSize.name}
            </p>
          </div>

          <div className="fleet-aircraft-item__seat-wrapper">
            <div className="fleet-aircraft-item__seat-detail">
              <span className="material-icons">airline_seat_recline_extra</span>
              {`${aircraft.quantityOfPassengers ||
                aircraft.model.quantityOfPassengers} ${TEXTS.AVAILABLE_PLACES}`}
            </div>
          </div>

          {governmentCertification && (
            <div className={Style.certifications}>
              <AircraftSecurityComponents.SafetyCertificationItem
                short={isMobile}
                item={mapPropertiesSafetyCertification(governmentCertification)}
                classNames={Style}
              />
            </div>
          )}
        </div>
      </div>

      <div className="fleet-aircraft-item__action-area">
        <div className="fleet-aircraft-item__action-area-blank-space"></div>

        <div className="fleet-aircraft-item__wrapper-amenities">
          {amenities.map((data, index) => (
            <div className="fleet-aircraft-item__amenity" key={index}>
              <img src={data.iconImageUrl} />
            </div>
          ))}
        </div>
        <div className="fleet-aircraft-item__wrapper-btn-book">
          <Link to={makeAircraftLink(aircraft, language)}>
            <div className="fleet-aircraft-item__btn-book">
              {' '}
              {TEXTS.QUOTATION}{' '}
              <div className="fleet-aircraft-item__btn-book-arrow">
                <span className="material-icons">arrow_forward</span>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default FleetAircraftItem;
